<template>
  <router-view />
</template>
<script>
import proxy from "@/api/proxy.js";

export default {
  data() {
    return {};
  },
  created() {},
  async mounted() {},
  watch: {},
  methods: {
    getProxy() {
      return proxy;
    },
  },
};
</script>
<style>
</style>